.wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 69px 140px 131px 278px;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

@media screen and (max-width: 1440px) {
  .wrapper {
    padding: 69px 70px 131px 200px;
  }
}

@media screen and (max-width: 992px) {
  .wrapper {
    padding: 112px 16px 16px 16px;
    height: auto;
    min-height: 100vh;
  }

  .container {
    height: 100%;
    overflow: visible;
  }
}

@media screen and (max-height: 650px) {
  .wrapper {
    height: auto;
  }
}
