.container {
  width: 398px;
  border: 8px solid var(--white);
  height: 49%;
  padding: 23px 32px 32px 32px;
  position: relative;
  overflow: hidden;
}

.container.tall {
  height: 100%;
  padding: 32px;
}

.container.tall .card {
  gap: 30px !important;
}
.card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 25px;
}

.card_img {
  width: 100%;
  height: calc(100% - 155px);
  object-fit: cover;
  flex-shrink: 0;
  object-position: bottom;
}

.card_description {
  height: 134px;
}

.card_description h3 {
  font-family: "NM";
  font-weight: 900;
  text-transform: capitalize;
  font-size: 34px;
  white-space: nowrap;
}

.card_description p {
  font-family: "ME";
  font-weight: 200;
  font-size: 14px;
  line-height: 19px;
  color: var(--gray);
}

.price {
  font-size: 44px;
  font-family: "NM";
  font-weight: 900;
}

.per {
  font-family: "ME";
  font-weight: 200;
  color: var(--green);
  font-size: 16px;
  padding-top: 8px;
}

.total {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;
}

.hover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--red);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.25s;
  padding: 32px;
  text-align: center;
  gap: 20px;
}

.hover_cont {
  width: 100%;
}

.hover.opacity {
  opacity: 1;
}

.hover_data {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hover_range {
  font-family: "ME";
  font-size: 24px;
  white-space: nowrap;
}

.hover_price {
  font-family: "NM";
  font-weight: 900;
  font-size: 32px;
  white-space: nowrap;
}

.hover_price sup {
  font-family: "ME";
  font-weight: 200;
  font-size: 16px;
}

.call {
  text-decoration: underline;
}

.hover_title {
  font-family: "ME";
  font-weight: 200;
  font-size: 14px;
}

.mobile {
  width: 100% !important;
  max-width: 358px !important;
  height: 321px !important;
  padding-bottom: 0 !important;
}

.mobile .card_description h3 {
  font-size: 24px;
}

.mobile .card_description p {
  font-size: 14px;
}
.mobile .card_description .price {
  font-size: 44px;
}

.mobile .card_description .per {
  font-weight: 200;
  padding: 0;
}

@media screen and (max-width: 1715px) {
  .container {
    width: 300px;
    padding: 16px;
  }

  .container.tall {
    padding: 16px;
  }

  .card_description h3 {
    font-size: 25px;
    margin-bottom: 8px;
  }

  .card_description p {
    line-height: 14px;
  }

  .hover {
    padding: 8px;
  }

  .hover_title {
    font-size: 12px;
  }

  .hover_range {
    font-size: 16px;
  }

  .hover_price {
    font-size: 18px;
  }

  .hover_price sup {
    font-size: 11px;
  }
}

@media screen and (max-width: 1125px) {
  .container {
    width: 240px;
    padding: 8px;
  }

  .card_description h3 {
    font-size: 20px;
  }

  .hover_range {
    font-size: 16px;
  }

  .hover_title {
    font-size: 12px;
  }

  .hover_price {
    font-size: 26px;
  }

  .price,
  .call {
    font-size: 34px;
  }
}

@media screen and (max-height: 851px) and (min-width: 1280px) {
  .container {
    padding: 16px !important;
  }
  .container.tall .card,
  .card {
    gap: 16px !important;
  }

  .card_img {
    height: calc(100% - 120px);
    object-position: center;
  }

  .card_description {
    height: 110px;
  }

  .card_description h3 {
    font-size: 25px;
  }

  .card_description p {
    font-size: 12px;
    line-height: 12px;
  }

  .call {
    display: block;
    transform: translateY(-10px);
  }

  .hover_title {
    font-size: 12px;
  }

  .hover_range {
    font-size: 16px;
  }

  .hover_price {
    font-size: 18px;
  }

  .hover_price sup {
    font-size: 11px;
  }
}
