.wrapper {
  width: 131px;
  height: 100%;
  padding: 17px 0 17px 9px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  width: 100%;
  height: 100%;
  background-color: var(--red);
  margin-block: 8px;
  display: flex;
  position: relative;
}

.burger {
  width: 100%;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
}

.nav_container {
  height: 100%;
  position: absolute;
  left: 100%;
  top: 0;
  overflow: hidden;
  z-index: 1;
  background-color: var(--red);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 80%;
  width: 100%;
  max-height: 704px;
  position: relative;
}

.page_span {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page_span::before {
  content: "";
  width: 0;
  height: 8px;
  background-color: var(--black);
  position: absolute;
  left: 0;
  transition: all 0.25s;
}
.page_span:hover::before {
  width: calc(100% + 60px);
}
.page_span:hover::after {
  content: "*";
  position: absolute;
  left: 100%;
  color: var(--black);
  font-size: 138px;
  font-family: "NM";
  font-weight: 900;
}

.nav_link {
  padding-left: 9px;
  font-size: 158px;
  font-family: "NM";
  font-weight: 900;
  text-transform: uppercase;
  color: var(--black);
  position: relative;
}

.home {
  position: absolute;
  right: 141px;
  top: 0;
}

.home_btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_icon,
.home_icon_active {
  position: absolute;
  transition: all 0.3s;
  top: 0;
}

.home_icon_active {
  opacity: 0;
  transform: translateY(-5px);
}

.home:hover .home_icon_active {
  opacity: 1;
}
.home:hover .home_icon {
  opacity: 0;
}

.letters {
  max-width: 122px;
}

.socials {
  position: absolute;
  right: 141px;
  bottom: 28px;
}

@media screen and (max-width: 1350px) {
  .nav_link {
    font-size: 130px;
  }
}

@media screen and (max-width: 1150px) {
  .nav_link {
    font-size: 100px;
  }
}

@media screen and (max-height: 900px) {
  .nav_link {
    font-size: 100px;
  }
  .navigation {
    max-height: 80%;
  }
}

@media screen and (max-height: 650px) {
  .wrapper {
    height: auto;
  }

  .container {
    height: 516px;
  }
}
