.container {
  width: 56px;
  height: 28px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 200;
}

.line {
  width: 100%;
  height: 4px;
  background-color: var(--black);
  border-radius: 4px;
  transition: all 0.3s;
}

.container.active .line:first-of-type,
.container.active .line:last-of-type {
  opacity: 0;
}

.container.active .close_line {
  opacity: 1;
}

.container.active .line:nth-of-type(2) {
  transform: rotate(25deg);
  transform-origin: center;
}

.close_line {
  width: 100%;
  height: 4px;
  background-color: var(--black);
  border-radius: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-25deg);
  opacity: 0;
  transition: all 0.3s;
}

.container.tiny {
  width: 24px;
  height: 14px;
}

.container.tiny .close_line {
  transform: translateY(-50%) rotate(-45deg);
}

.container.tiny.active .line:nth-of-type(2) {
  transform: rotate(45deg);
  transform-origin: center;
  background-color: var(--black);
}

.container.tiny .close_line,
.container.tiny .line {
  background-color: var(--white);
  height: 2px;
}

.container.tiny.active .close_line {
  background-color: var(--black);
}
