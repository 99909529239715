.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.right {
  height: 100%;
  display: flex;
  gap: 16px;
}

.right > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.desc {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.desc h1 {
  font-size: 80px;
  line-height: 80px;
  text-transform: uppercase;
  font-family: "ME";
  font-weight: 900;
}

.desc h6 {
  font-family: "NM";
  font-size: 24px;
  font-weight: 200;
  color: var(--green);
  margin-top: 16px;
}

.desc p {
  font-family: "ME";
  font-size: 14px;
  font-weight: 200;
  color: var(--gray);
  max-width: 520px;
  margin-top: 24px;
  margin-bottom: 32px;
}

.actions {
  display: flex;
  gap: 16px;
  cursor: pointer;
}

.button {
  width: 118px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 12px;
  font-family: "NM";
  font-weight: 200;
  color: var(--gray);
  transition: all 0.3s;
  flex-shrink: 1;
}

.actions:hover .button {
  background-color: var(--green);
}

.single {
  transition: all 0.2s;
}

.actions:hover .single {
  transform: translateX(-130%);
  opacity: 0;
}

.actions:hover .double {
  transform: translateX(-120%);
  transition: all 0.2s;
  opacity: 1;
}

.rect {
  position: absolute;
  left: 0;
  top: 0;
}

.double {
  opacity: 0;
}

@media screen and (max-width: 1575px) {
  .desc h1 {
    font-size: 65px;
    line-height: 65px;
    word-break: break-all;
  }
}

@media screen and (max-width: 1200px) {
  .desc h1 {
    font-size: 40px;
    line-height: 40px;
  }
}

@media screen and (max-height: 650px) {
  .container {
    height: 516px;
  }
}
