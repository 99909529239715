.container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 154px;
  justify-content: space-between;
}

.left {
  width: 100%;
  max-width: 536px;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.right {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.green_flake {
  width: 183px;
  position: absolute;
  bottom: 118px;
  right: -62px;
}

.title {
  font-size: 123px;
  line-height: 123px;
  font-family: "ME";
  font-weight: 900;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;
}

.red_flake {
  position: absolute;
  top: 0;
  left: -64px;
}

.contact {
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 34px;
}

.cont_l {
  font-family: "ME";
  font-weight: 900;
  font-size: 14px;
  width: 50%;
}

.cont_r {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 900;
  font-family: "ME";
  gap: 20px;
}

.email {
  font-weight: 200;
}

.cont_address {
  font-weight: 200;
  color: var(--gray);
}

.map {
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  align-items: center;
  width: 140px;
  text-transform: uppercase;
  font-family: "ME";
  font-weight: 900;
  color: var(--red);
}

@media screen and (max-width: 1700px) {
  .container {
    gap: 56px;
  }
}

@media screen and (max-height: 860px) and (min-width: 992px) {
  .contact {
    margin-bottom: 22px;
  }
}

@media screen and (max-height: 800px) and (min-width: 992px) {
  .title {
    font-size: 85px;
  }
  .green_flake {
    width: 150px;
  }
  .red_flake {
    top: 25px;
  }
}

@media screen and (max-height: 673px) and (min-width: 992px) {
  .title {
    font-size: 70px;
    margin-bottom: -20px;
  }
}

@media screen and (max-height: 650px) and (min-width: 992px) {
  .container {
    height: 516px;
  }
  .title {
    font-size: 100px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1670px) {
  .title {
    font-size: 100px;
  }
}

@media screen and (max-width: 1440px) {
  .left {
    max-width: 470px;
  }
}

@media screen and (max-width: 1285px) {
  .title {
    font-size: 75px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    gap: 0;
  }
  .left {
    width: 100%;
    padding-top: 154px;
    max-width: 100%;
  }
  .right {
    height: 200px;
    position: absolute;
    right: 0;
    max-width: 75%;
  }
  .green_flake {
    width: 63px;
    height: 63px;
    right: 12px;
    bottom: 0;
    transform: translateY(50%);
  }

  .title {
    font-size: 70px;
    line-height: 70px;
  }
  .title:last-of-type {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 992px) {
  .title {
    font-size: 56px;
    line-height: 56px;
  }
}

@media screen and (max-width: 556px) {
  .contact {
    flex-direction: column;
    gap: 8px;
    font-size: 12px;
  }
  .cont_l {
    width: 100%;
  }
  .cont_r {
    width: 100%;
    gap: 8px;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 392px) {
  .title {
    font-size: 42px;
    line-height: 42px;
  }
}
