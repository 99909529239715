.first_type {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 108px;
  padding-inline: 5px;
}

.cover {
  top: 0;
  right: 0;
  object-fit: cover;
  object-position: bottom;
  position: absolute;
  z-index: -1;
}

.first_type .cover {
  width: 100%;
  height: 408px;
}

.second_type {
  padding-top: 40px;
}

.second_type .cover {
  width: 80%;
  height: 362px;
}

.title {
  font-family: "ME";
  font-weight: 900;
  font-size: 65px;
  line-height: 70px;
}

sup {
  font-size: 32px;
}

.subtitle {
  font-size: 18px;
  font-family: "NM";
  color: var(--green);
}

.list {
  font-family: "ME";
  font-weight: 900;
  font-size: 14px;
  padding-left: 25px;
  margin-bottom: 3px;
}

.list_item {
  margin-bottom: 16px;
}

.item_text {
  font-weight: 200;
  color: var(--gray);
}

.navigation {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.box {
  font-family: "NM";
  font-size: 12px;
  width: 44px;
  height: 44px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
}

.arrows {
  cursor: pointer;
}

.square {
  position: absolute;
  transform: translateY(-2px);
  left: 0;
  top: 0;
}

.first_type .subtitle {
  margin-bottom: 231px;
}

.second_type .subtitle {
  margin-bottom: 85px;
}

.second_type .list {
  margin-top: 57px;
}

.className {
  height: 178px;
  width: 80% !important;
  max-width: unset;
  border-width: 4px;
}

.flake {
  position: absolute;
  right: 0;
  top: 408px;
  transform: translateY(-50%);
}

.custom {
  margin-bottom: 49px;
}

@media screen and (max-width: 407px) {
  .second_type .title {
    font-size: 50px;
    line-height: 50px;
  }
}

@media screen and (max-width: 375px) {
  .title {
    font-size: 50px;
    line-height: 50px;
  }
  sup {
    font-size: 25px;
  }
}

@media screen and (max-width: 650px) and (max-height: 650px) {
  .container {
    padding-bottom: 32px;
  }
}
