.container {
  border: 7px solid var(--black);
  width: 699px;
  max-width: 70%;
  height: 366px;
  max-height: 50%;
  z-index: 10;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.letter {
  position: absolute;
  top: 16px;
  right: 16px;
  font-family: "ME";
  font-weight: 900;
  font-size: 14px;
  color: var(--black);
}

@media screen and (max-width: 1280px) {
  .container {
    border-width: 4px;
  }
}

@media screen and (max-width: 1600px) {
  .letter {
    top: 8px;
    right: 8px;
  }
}
