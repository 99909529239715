.container {
  width: 100%;
  height: 100vh;
  background-color: var(--bg-modal);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}

.window {
  width: 514px;
  max-width: 90%;
  height: 261px;
  max-height: 95vh;
  background-color: var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 11px solid var(--red);
  font-size: 44px;
  font-family: "ME";
  font-weight: 900;
  padding: 16px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}

.close {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.error {
  font-size: 24px;
}

@media screen and (max-width: 992px) {
  .close {
    width: 16px;
    height: 16px;
    top: 8px;
    right: 8px;
  }

  .error {
    font-size: 16px;
  }
}

@media screen and (max-width: 390px) {
  .window {
    font-size: 34px;
  }
}
