/* Reset css */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "MERegular";
}
a {
  text-decoration: none;
  color: inherit;
}

/* Custom CSS */
:root {
  --black: #000000;
  --white: #ffffff;
  --gray: #a1a2a3;
  --bg-color: #151619;
  --red: #fd614d;
  --green: #ccfd34;
  --hover-green: #d4fd4dcc;
  --bg-modal: #15161980;
}
body {
  background-color: var(--bg-color);
  color: var(--white);
}

/* Hide scrollbar */
::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
