.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.white svg,
.white svg path {
  fill: white !important;
}
