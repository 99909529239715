.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "ME";
  font-weight: 200;
  margin-bottom: 96px;
}

.row {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  width: 100%;
}

input {
  width: 100%;
  height: 62px;
  padding-inline: 24px;
  color: var(--bg-color);
  outline: none;
  border: none;
}

textarea {
  width: 100%;
  height: 208px;
  padding: 20px 24px;
  resize: none;
  color: var(--bg-color);
  outline: none;
  border: none;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.button {
  width: 138px;
  height: 47px;
  text-transform: capitalize;
  outline: none;
  border: none;
  background-color: var(--green);
  color: var(--bg-color);
  font-weight: 500;
  cursor: pointer;
}

.button:hover {
  background-color: var(--hover-green);
}

.button:disabled {
  background-color: var(--hover-green);
  cursor: not-allowed;
}

.input_cont,
.area_cont {
  width: 100%;
  position: relative;
}

.area_cont {
  margin-bottom: 16px;
}

.error {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  color: var(--red);
  font-size: 12px;
  font-weight: 200;
  font-family: "ME";
  white-space: nowrap;
}

.spam {
  display: flex;
  gap: 11px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.spam span {
  font-weight: 200;
  font-family: "ME";
  font-size: 12px;
  cursor: pointer;
}

@media screen and (max-height: 975px) and (min-width: 992px) {
  .container {
    margin-bottom: 46px;
  }
}

@media screen and (max-height: 860px) and (min-width: 992px) {
  .container {
    margin-bottom: 22px;
  }
  .row {
    margin-bottom: 16px;
  }
  .error {
    font-size: 10px;
    top: calc(100% + 1px);
  }
}

@media screen and (max-height: 800px) and (min-width: 992px) {
  textarea {
    height: 120px;
  }
  input,
  button {
    height: 50px;
  }
  .container {
    margin-bottom: 16px;
  }
  .area_cont {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 556px) {
  .row {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }
  input {
    height: 48px;
  }
  textarea {
    height: 120px;
  }
  .area_cont {
    margin-bottom: 8px;
  }
  .button {
    height: 44px;
  }
  .container {
    margin-bottom: 16px;
  }

  .error {
    font-size: 8px;
  }

  .area_cont .error {
    top: 100%;
  }
}
