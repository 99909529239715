.header {
  width: 100%;
  height: 87px;
  padding-inline: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding-bottom: 7px;
  background-color: var(--bg-color);
}

.menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--red);
  color: var(--black);
  padding: 130px 16px 63px 16px;
  overflow: scroll;
  transform: translateY(-100vh);
  transition: all 0.2s;
}

.menu.active {
  transform: translateY(0);
}

.navigation {
  display: flex;
  flex-direction: column;
  margin-bottom: 96px;
}

.navlink {
  font-size: 62px;
  line-height: 73px;
  font-family: "NM";
  font-weight: 900;
  line-break: strict;
}

.title,
.map,
.mobile {
  font-family: "ME";
  font-weight: 900;
  font-size: 12px;
}

.address,
.email {
  font-family: "ME";
  font-weight: 200;
  font-size: 12px;
}

.map {
  text-transform: uppercase;
  text-decoration: line-through;
}

.contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
  gap: 6px;
  flex-wrap: wrap;
}

@media screen and (max-width: 379px) {
  .navlink {
    font-size: 50px;
    line-height: 60px;
  }
}
