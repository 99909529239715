.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 102px;
}

.flake {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
}

.left {
  width: 100%;
  max-width: 398px;
}

.right {
  width: 60%;
  height: 100%;
  position: relative;
}

.title {
  font-family: "ME";
  font-weight: 900;
  font-size: 68px;
}

.title sup {
  font-family: "ME";
  font-weight: 900;
  font-size: 37px;
}

.subtitle {
  font-family: "NM";
  font-size: 24px;
  color: var(--green);
  margin-bottom: 40px;
}

.list {
  font-family: "ME";
  font-weight: 900;
  padding-left: 25px;
  font-size: 14px;
  line-height: 21px;
}

.list_item {
  margin-bottom: 32px;
}

.item_text {
  font-weight: 200;
  color: var(--gray);
}

.actions {
  display: flex;
  gap: 16px;
  cursor: pointer;
}

.button {
  width: 118px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 12px;
  font-family: "NM";
  font-weight: 200;
  color: var(--gray);
  transition: all 0.3s;
  flex-shrink: 1;
}

.actions:hover .button {
  background-color: var(--green);
}

.single {
  transition: all 0.2s;
}

.actions:hover .single {
  transform: translateX(-130%);
  opacity: 0;
}

.actions:hover .double {
  transform: translateX(-120%);
  transition: all 0.2s;
  opacity: 1;
}

.rect {
  position: absolute;
  left: 0;
  top: 0;
}

.double {
  opacity: 0;
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.letter {
  position: absolute;
  bottom: 16px;
  left: 16px;
  font-family: "ME";
  font-weight: 900;
  font-size: 14px;
  color: var(--black);
}

.inner_img {
  position: absolute;
  width: 637px !important;
  top: 120px;
  left: -70px;
}

@media screen and (max-width: 1600px) {
  .letter {
    bottom: 8px;
    left: 8px;
  }
}
