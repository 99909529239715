.logo {
  display: flex;
}

.block {
  height: 31px;
  width: 2px;
  margin: auto;
  background-color: var(--red);
  flex-shrink: 0;
  transition: all 0.4s;
}

.img {
  transform: rotate(-90deg);
  max-width: 30px;
  margin: 0 !important;
}

.img:first-of-type {
  transform: rotate(-90deg) translateY(5px);
}
