.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  position: relative;
}

.left {
  max-width: 536px;
  z-index: 10;
  padding-top: 7%;
}

.head_title {
  font-family: "NM";
  font-size: 44px;
  font-weight: 200;
}

.title {
  font-family: "ME";
  font-size: 213px;
  text-transform: uppercase;
  line-height: 200px;
}

.subtitle {
  font-family: "HNM";
  font-size: 21px;
  margin: 22px 0 24px 0;
  white-space: nowrap;
}

.text {
  font-family: "HNM";
  font-weight: 200;
  font-size: 14px;
  color: var(--gray);
}

.right {
  height: 100%;
  width: 100%;
  padding-top: 35px;
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.flake {
  position: absolute;
  right: 0;
  top: 50px;
  width: 122px;
  transform: translateX(50%);
  z-index: 30;
}

@media screen and (max-width: 1600px) {
  .title {
    font-size: 190px;
    line-height: 190px;
  }
  .left {
    max-width: 420px;
  }
  .subtitle {
    white-space: unset;
  }
}

@media screen and (max-width: 1440px) {
  .title {
    font-size: 190px;
    line-height: 190px;
  }
  .left {
    max-width: 340px;
  }
  .subtitle {
    white-space: unset;
  }
}

@media screen and (max-width: 1100px) {
  .title {
    font-size: 170px;
    line-height: 170px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    align-items: flex-start;
  }
  .title {
    font-size: 118px;
    line-height: 100px;
  }
  .left {
    padding-top: 145px;
    width: 100%;
    max-width: 100%;
  }
  .right {
    padding-top: 0;
    height: 373px;
    position: absolute;
    width: 75%;
    right: 0;
  }
  .subtitle {
    white-space: unset;
    max-width: 360px;
    font-size: 18px;
  }
  .head_title {
    font-size: 24px;
    margin-bottom: 38px;
  }
  .text {
    font-size: 12px;
  }

  .flake {
    left: -69px;
    right: unset;
    top: -20px;
    width: 68px;
  }
}

@media screen and (max-height: 650px) {
  .container {
    padding-bottom: 32px;
  }
}
