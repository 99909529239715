.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.desc {
  width: 100%;
  max-width: 358px;
  margin: auto;
  margin-bottom: 52px;
}

.title {
  font-family: "ME";
  font-weight: 900;
  text-transform: uppercase;
  font-size: 32px;
  margin-bottom: 7px;
}

.subtitle {
  font-family: "NM";
  font-size: 24px;
  color: var(--green);
  margin-bottom: 8px;
}

.description {
  font-family: "ME";
  font-weight: 200;
  font-size: 14px;
  color: var(--gray);
  min-height: 76px;
}

.pagination {
  margin-top: 60px;
  display: flex;
  gap: 16px;
}

.page {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 12px;
}

.page.active {
  background-color: var(--green);
  color: var(--black);
}

.square {
  position: absolute;
}

@media screen and (max-width: 365px) {
  .title {
    font-size: 28px;
  }
}

@media screen and (max-width: 650px) {
  .container {
    padding-bottom: 32px;
  }
}
