.location_img {
  position: absolute;
  object-fit: cover;
  top: 0;
  right: 0;
  height: 732px;
  max-width: 80%;
  max-height: 85%;
  z-index: -1;
}

.location {
  position: absolute;
  right: 16px;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;
  z-index: 2;
}

.location_desc {
  text-align: right;
  padding-bottom: 30px;
  font-size: 12px;
  font: "ME";
  font-weight: 200;
  color: var(--gray);
}

.location_desc span {
  font-weight: 900;
  color: var(--white);
}
.location_desc a {
  font-weight: 900;
  text-transform: uppercase;
  color: var(--red);
  text-decoration: line-through;
}
.location_desc a svg {
  margin-right: 5px;
}

.map_img {
  width: 558px;
  height: 332px;
  max-width: 25vw;
  max-height: 30vh;
  object-fit: cover;
}

.description {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.description h1 {
  font-family: "ME";
  font-weight: 900;
  font-size: 110px;
  line-height: 106px;
  z-index: 4;
}

.card {
  margin-top: 32px;
}

.card h2 {
  font-size: 62px;
  font-family: "NM";
  font-weight: 900;
}
.card p {
  font-family: "ME";
  font-size: 14px;
  max-width: 360px;
}
.main {
  font-weight: 900;
}
.secondary {
  font-weight: 200;
  color: var(--gray);
}

.location_swiper {
  z-index: 50;
  margin-top: 300px;
  min-height: 114px;
}
.location_swiper_slide {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.location_swiper_slide h2 {
  font-size: 52px;
}
.location_swiper_slide p {
  font-size: 14px;
  max-width: 80%;
}

.swiper_navigation {
  width: 100%;
  height: 44px;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;
  padding-bottom: 50px;
}
.square {
  position: absolute;
  transform: translateY(-2px);
}
.swiper_navigation span {
  font-size: 12px;
  font-family: "NM";
  color: var(--gray);
}

.flake {
  position: absolute;
  right: -48px;
  top: 191px;
}

@media screen and (max-width: 992px) {
  .location_img {
    max-height: 285px;
    max-width: 73%;
  }

  .location {
    left: 0;
    right: unset;
    top: 248px;
    justify-content: flex-start;
    align-items: flex-start;
    height: 182px;
  }

  .map_img {
    max-width: 80vw;
    max-height: 182px;
  }

  .description {
    justify-content: flex-start;
    padding-top: 40px;
    height: auto;
  }

  .description h1 {
    font-size: 38px !important;
    line-height: 39px !important;
  }
}

@media screen and (max-height: 960px) {
  .description h1 {
    font-size: 90px;
    line-height: 90px;
  }

  .card {
    margin-top: 22px;
  }

  .card h2 {
    font-size: 50px;
  }

  .flake {
    top: 70px;
    max-width: 130px;
  }
}

@media screen and (max-height: 860px) {
  .description h1 {
    font-size: 70px;
    line-height: 70px;
  }
}

@media screen and (max-height: 790px) {
  .card h2 {
    font-size: 45px;
  }
  .description h1 {
    font-size: 60px;
    line-height: 60px;
  }
}

@media screen and (max-height: 720px) {
  .card h2 {
    font-size: 35px;
  }
  .description h1 {
    font-size: 50px;
    line-height: 50px;
  }
}

@media screen and (max-width: 375px) {
  .description h1 {
    font-size: 31px !important;
    line-height: 31px !important;
  }
}
