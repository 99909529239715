.container {
  width: 0;
  height: calc(100vh - 199.54px);
  position: absolute;
  top: 68.98px;
  left: 9px;
  background-color: var(--red);
  z-index: 50;
}

.container.active {
  animation: loading 1s forwards;
}

@keyframes loading {
  50% {
    width: calc(100% - 9px);
  }
  100% {
    width: 0;
  }
}

@media screen and (max-height: 650px) {
  .container {
    height: 516px;
  }
}
